import * as React from "react";
import { Alert, Button, FormControl, Grid, Input, Snackbar, Stack, Typography } from "@mui/material";
import axios from 'axios';
import certEmailHTML from "../assets/valentines.txt";

const recipient = 'sarahshaker01@gmail.com';

const noResponses = [
    "no",
    "nope",
    "nah",
    "not really",
    "i don't think so",
    "negative",
    "certainly not",
    "i'd rather not",
    "unfortunately, no",
    "no thanks",
];
  
const yesResponses = [
    "yes",
    "yep",
    "yea",
    "yeah",
    "yup", // Added 'yup'
    "absolutely",
    "certainly",
    "definitely",
    "of course",
    "sure",
    "yes, please",
    "indeed",
    "affirmative",
    "yer"
];

const FormInput = (props) => {
    const name = props.name;
    const num = props.num;
    const id = props.name.toLowerCase();
    const display = num !== props.currentPage ? 'none' : 'unset';

    return (
        <Grid id={`input-${num}`} className="form-input" item xs={props.xs} sx={{display}}>
            <FormControl fullWidth>
                <Typography sx={{fontSize: '2rem', fontWeight: '700'}}>{name}</Typography>
                <Input id={id} name={id} onChange={props.onChange} sx={{fontSize: '1.5rem'}}/>
            </FormControl>
        </Grid>
    );
};

const Form = () => {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [formData, setFormData] = React.useState({});
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [answer, setAnswer] = React.useState('');
    const [contactSuccess, setContactSuccess] = React.useState({status: 'error', message: 'An Error Occurred'});

    const validate = (ans) => validation[currentPage](ans);

    const submit = async (e) => {
        e.preventDefault();
        // await sendBeginEmail();

        // return;

        if (currentPage < questions.length - 1) {
            let validateMessage = validate(answer);
            if (!validateMessage){
                setCurrentPage(currentPage + 1);
            } else {
                setContactSuccess({status: 'error', message: validateMessage});
                setSnackOpen(true);
            }

            return;
        }

        const resp = await fetch(certEmailHTML)

        const formData2 = {
            name: 'Mystery Valentine\'s service',
            subject: 'Yayzies! Your valentine has been chosen!',
            message: await resp.text(),
            recipient: recipient,
            overrideHtml: true,
            questions: formData
        }

        console.log(formData2);

        try{
            const response = await axios.post('/.netlify/functions/email', formData2);

            setContactSuccess({status: 'success', message: response?.data});
        }
        catch(err){
            setContactSuccess({status: 'error', message: err?.response?.data});
        }

        setSnackOpen(true);
    }

    // eslint-disable-next-line no-unused-vars
    const sendBeginEmail = async (e) => {
        formData.name = 'Mystery Valentine\'s service';
        formData.subject = 'Do you have a valentine? ';

        formData.message = 'Hey there! Do you have a valentine yet? Take this quick survey to find a match for you!!<br> <a href="https://valentine.shaquillesadiq.com/">Take a survey</a>';
        formData.recipient = recipient;

        try{
            const response = await axios.post('/.netlify/functions/email', formData);

            setContactSuccess({status: 'success', message: response?.data});
        }
        catch(err){
            setContactSuccess({status: 'error', message: err?.response?.data});
        }

        setSnackOpen(true);
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAnswer(value);

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const questions = [
        "What is your full name?", 
        "Do you have a valentine?",
        "Would you like to have a valentine?",
        "Well I've got just the person for you...",
        "Do you accept \"Shaquille Sadiq\" as your valentine?",
        "Yayzies! Continue to recieve your official certificate via email!\n Add a custom message for him!"
    ];

    const buttons = [
        "Next", 
        "Next", 
        "Next",
        "Who??",
        "OF COURSE",
        "Yayy!"
    ];

    const validation = [
        (ans) => ans.toLowerCase() !== "sarah mohyeldin shaker" ? "No your FULL name": false,
        (ans) => !noResponses.includes(ans.toLowerCase()) ? "Try again": false,
        (ans) => !yesResponses.includes(ans.toLowerCase()) ? "Try again": false,
        () => false,
        (ans) => noResponses.includes(ans.toLowerCase()) === "no" ? "Try again": false,
        () => false,
    ];

    const buildQuestions = () => {
        const arr = [];


        questions.forEach((name, i) => {
            arr.push(<FormInput num={i} currentPage={currentPage} name={name} onChange={handleInputChange} xs={12}/>);
        });

        return arr;
    };
    
    return (
        <Stack justifyContent="center" alignItems="center" spacing={2} sx={{height: '100%', padding: '1rem', backgroundColor: '#ffd6d69c'}}>
            <form onSubmit={submit} netlify='netlify'>
                <Grid container spacing={2}>
                    {buildQuestions()}
                    <Grid item xs={12}>
                        <Button variant="contained" color="crimsonRed" type="submit">{buttons[currentPage]}</Button>
                    </Grid>
                </Grid>
            </form>
            
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={snackOpen} autoHideDuration={3000} onClose={() => setSnackOpen(false)} key={"snackbar"}>
                <Alert className="test" variant="filled" severity={contactSuccess.status} sx={{ fontSize: "1.5rem", width: "100%" }}>{contactSuccess.message}</Alert>
            </Snackbar>
        </Stack>
    );
}

export default Form;
