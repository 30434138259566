import * as React from "react";
import { Grid } from "@mui/material";

const PageNotFound = () => {
    // const [test, setTest] = React.useState("hello world");

    return (
        <Grid container spacing={2} sx={{maxInlineSize: '100vw', padding: '1rem'}}>
            <Grid item xs={12}>
                <h2>404: Page Not Found</h2>
            </Grid>
        </Grid>
    );
}

export default PageNotFound;
