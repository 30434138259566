import "./App.css";
import * as React from "react";
import Valentine from "./pages/Valentine.js";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ErrorBoundary from "./theme/ErrorBoundary.js";
import { ThemeContext, Theme } from "./theme/ThemeContext.js";
import SDVSnackbar from "./components/SDVSnackbar";
import SnackContext from "./components/SnackContext";
import { ThemeProvider } from '@mui/material/styles';
import PageNotFound from "./pages/PageNotFound";
import Form from "./components/Form.js";

const App = () => {
    const [theme, setTheme] = React.useState(Theme);
    const [snackMessage, setSnackMessage] = React.useState();
    const context = { theme, setTheme };

    return (
        <ThemeContext.Provider value={context}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <SnackContext.Provider value={{ snackMessage, setSnackMessage }}>
                        <BrowserRouter>
                            <Valentine>
                                <Routes>
                                    <Route exact path="/" element={<Form />} />
                                    <Route exact path="/*" element={<PageNotFound/>} />
                                </Routes>
                            </Valentine>
                        </BrowserRouter>
                        <SDVSnackbar>{snackMessage}</SDVSnackbar>
                    </SnackContext.Provider>
                </ErrorBoundary>
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default App;
