import * as React from "react";
import { Alert, Snackbar } from "@mui/material";

const SDVSnackbar = (props) => {
    const [open, setOpen] = React.useState(false);

    const response = props?.children;
    const status = response?.status;
    const message = response?.message || response; //if message isnt set then assume string sent instead of json

    React.useEffect(() => {
        if(response){
            setOpen(true);
        }
    }, [response]);

    let severity = 'info';

    switch(status){
        case 200:
            severity = 'success';
            break;
        case 400:
            severity = 'error';
            break;
        case undefined:
            severity = 'info';
            break;
        default:
            severity = 'warning';
            break;
    }

    const close = () => {
        setOpen(false);
    }

    return (
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={3000} onClose={close}>
            <Alert onClose={close} severity={severity} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SDVSnackbar;
