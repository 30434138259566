import * as React from "react";
import { createTheme } from "@mui/material/styles";

const headingFont = (largeSize, smallSize = largeSize) => {
    return {
        fontFamily: 'var(--heading-font)',
        fontSize: largeSize, 
    }
};

//shared theme stuff
const theme = {
    typography: {
        fontFamily: 'var(--body-font)',
        h1: headingFont('2.5rem'),
        h2: headingFont('1.5rem'),
        h3: headingFont('1.17rem'),
        h4: headingFont('1rem'),
        h5: headingFont('.83rem'),
        h6: headingFont('.67rem'),
        button: {fontSize: '1.2rem'}
    },
    palette: {
        crimsonRed: {
            main: '#DC143C',
            light: '#FF5765',
            dark: '#A3000F',
            contrastText: '#FFFFFF'      
        },   
    }
}

const palette=  {
    crimsonRed: {
        main: '#DC143C',
        light: '#FF5765',
        dark: '#A3000F',
        contrastText: '#FFFFFF'      
    },   
};

const LightTheme = createTheme({
    ...theme,
    palette: {
        ...palette,
        background: {
            default: '#ffffff',
            paper: 'var(--rose-petal-pink)',
            paper2: '#ffffff',
        },
    },
});

const DarkTheme = createTheme({
    ...theme,
    palette: {
        ...palette,
        mode: 'dark',
        divider: '#282828',
        background: {
            default: '#181818',
            paper: '#1D1D1D',
            paper2: '#282828',
        },
    },
});

//get dark mode setting from local storage or default to dark mode if undefined
const darkMode = localStorage.getItem('dark-mode') ?? 'false';
const Theme = darkMode === 'true' ? DarkTheme : LightTheme;

const ThemeContext = React.createContext({
    theme: Theme,
    setTheme: () => {}
});

export { ThemeContext, Theme, DarkTheme, LightTheme };