import * as React from "react";
import Header from "../components/Header";
import { Stack } from "@mui/material";
// import { ThemeContext } from "../theme/ThemeContext";
import ScrollingCollage from "../components/ScrollingCollage";

const Site = (props) => {
    // const [test, setTest] = React.useState("hello world");
    // const {theme} = React.useContext(ThemeContext);

    const pageStyle = {
        minBlockSize: '100vh',
        blockSize: '100vh',
        maxInlineSize: '100vw',
        transition: '0.5s'
    };

    return (
        <Stack direction="column" sx={pageStyle}>
            <Header/>
            <ScrollingCollage/>
            {props.children}
        </Stack>
    );
}

export default Site;
