import React from 'react';

const importAll = (r) => {
  return r.keys().map(r);
}

const photos = importAll(require.context('../assets/photos', false, /\.(png|jpe?g|svg)$/));

const ScrollingCollage = () => {
  return (
    <div className="collage-container">
      {photos.map((photo, index) => (
        <img key={index} src={photo} alt="" className="collage-photo" />
      ))}
    </div>
  );
};

export default ScrollingCollage;
