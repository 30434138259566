import * as React from "react";
import { Stack, Divider, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import { ThemeContext } from "../theme/ThemeContext";
import { styled } from "@mui/material/styles";

const RouterLink = styled(Link)(({theme}) => ({
    color: theme.palette.text.primary,
}));

const Header = () => {
    const {theme} = React.useContext(ThemeContext);

    const headerStyle = {
        backgroundColor: theme.palette.background.paper,
        borderBlock: '6px double var(--romantic-blush)',
        // borderInlineEnd: {md: border},
        boxSizing: 'border-box',
        paddingBlock: '0.5rem',
        position: 'sticky',
        textAlign: 'center',
        transition: '0.5s',
        top: '0',
        zIndex: 100
    };

    const logoStyle = {
        height: {xs: '50px', md: '75px'},
        width: {xs: '50px', md: '75px'},
        paddingInlineStart: {xs: '1rem', md: '2rem'},
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    }

    const logoImgStyle = {
        height: 'auto',
        width: '100%'
    }

    const divider = <Divider orientation="horizontal" flexItem />;

    return (
        <Stack direction="row" divider={divider} spacing={2} sx={headerStyle} alignItems='center'>
            <RouterLink className='logo' to='/' sx={logoStyle}>
                <img src={logo} alt="logo" style={logoImgStyle}/>
            </RouterLink>
            <Typography variant="h1" sx={{width: '100%'}}>Valentine's 2024</Typography>
        </Stack>
    );
}

export default Header;
